import * as React from "react";
import PageLayout from "../../PageLayout";
import SectionLayout from "../../SectionLayout";
import { Helmet } from "react-helmet";
import background from "../../images/electrical/banner.jpg"
import HowWeCanHelp from "../../components/HowWeCanHelp";
import {Gallery} from "../../components/Gallery";
import img1 from "../../images/electrical/1.jpg";
import img2 from "../../images/electrical/2.jpg";
import img3 from "../../images/electrical/3.jpg";
import img4 from "../../images/electrical/4.jpg";
const images = [
    {
        original: img1,
    },
    {
        original: img2,
    },
    {
        original: img3,
    },
    {
        original: img4,
    },
];
const Electrical = () => {
    return (
        <PageLayout
            noButton
            image={background}
            title={"Remote Electrical Services"}
            navSize={"h-96"}
            titleStyles={"xl:mt-12 lg:mt-20 md:mt-16 sm:mt-20 mt-16"}
        >
            <Helmet>
                <title>
                    EPG Solutions | Remote Electrical Services
                </title>
                <meta
                    name="description"
                    content="Electrical contracting, design and installation, de-commissioning, commissioning, relocation, electrical upgrades and preventative maintenance."
                />
            </Helmet>
            <div className={"bg-epg-yellow h-5"} />
            <SectionLayout bgColor={"bg-epg-lightgray"}>
                <p
                    className={
                        "text-center font-exo2 leading-8 text-xl py-5"
                    }
                >
                    E-P-G Solutions offers a range of electrical services available from urban centres right out to the most remote communities of the Territory.
                </p>
            </SectionLayout>
            <SectionLayout bgColor={"bg-epg-white"}>
                <div className={"bg-epg-white pt-16 pb-8"}>
                    <p className={"leading-7 lg:leading-8 lg:text-lg font-exo2 text-epg-black"}>
                        We have the experience and the knowledge to take a project from
                        concept and design right through to installation and testing including
                        decommissioning old systems, setting up temporary power to ensure
                        business can continue as usual, commissioning the new station,
                        restoring power and conducting training with operators.
                    </p>
                    <p className={"mt-5 leading-7 lg:leading-8 lg:text-lg font-exo2 text-epg-black"}>
                        We understand the challenges of power generation in isolated areas and
                        have the equipment and supplier network to provide the full service to
                        our customers no matter how challenging the project.
                    </p>
                    <p className={"mt-5 leading-7 lg:leading-8 lg:text-lg font-exo2 text-epg-black"}>
                        Our services include:
                    </p>
                    <ul className={"list-disc p-4 grid lg:leading-8 lg:text-lg lg:grid-cols-2 leading-7 font-exo2 text-epg-black"}>
                        <div>
                            <li>Electrical contracting</li>
                            <li>Design and installation</li>
                            <li>De-commissioning</li>
                            <li>Commissioning</li>
                        </div>
                        <div>
                            <li>Relocation</li>
                            <li>Electrical Upgrades</li>
                            <li>Annual Preventative Maintenance</li>
                        </div>
                    </ul>
                </div>
                <div className={"bg-epg-white pt-5"}>
                    <Gallery images={images} additionalClass={{ height: 200 }} />
                </div>
            </SectionLayout>
            <HowWeCanHelp />
        </PageLayout>
    );
};

export default Electrical;
